import React from 'react';
import { Link } from 'react-router-dom';
import cardimg1 from '../Assets/Images/Faq.png';
import cardimg2 from '../Assets/Images/TroubleShooting.png';
import cardimg3 from '../Assets/Images/BestPractise.png';
import cardimg4 from '../Assets/Images/Usingtheapp.png';
import cardimg5 from '../Assets/Images/OrganizationUpdate.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// Import other images as needed

function Knowledge() {
  // Define an array of card data
  const cardsData = [
    { link: "/KnowledgeBase", image: cardimg1, heading: "FAQ" },
    { link: "/KnowledgeBase", image: cardimg2, heading: "Troubleshooting Tips" },
    { link: "/KnowledgeBase", image: cardimg3, heading: "Best Practices" },
    { link: "/KnowledgeBase", image: cardimg4, heading: "“Using the App”Demo" },
    { link: "/KnowledgeBase", image: cardimg5, heading: "Organization Update" },
    // Add more objects for additional cards with different data
  ];

  return (
    <div className='py-20 px-[7%] mx-auto'>
      <h2 className='text-3xl xl:text-5xl font-semibold text-center' data-aos="fade-up" data-aos-duration="1500">Knowledge Base</h2>
 
      <div className='grid sm:grid-cols-2 max-w-[1920px] mx-auto md:grid-cols-3 lg:grid-cols-5 xl:justify-between w-[100%] items-center gap-4 my-10'>
        {/* Map over the array to render each card */}
        {cardsData.map((card, index) => (
          <Link key={index} to={card.link}>
            <div style={{ position: 'relative' }}>
              <img src={card.image} alt={card.heading} style={{ width: '100%', height: 'auto' }} className='rounded-lg' data-aos="flip-up" data-aos-duration="1500"/>
              <h3 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }} className='text-center text-white text-xl font-semibold'>{card.heading}</h3>
            </div>
          </Link>
        ))}
      </div>
      <Link to="/KnowledgeBase" data-aos="fade-down" data-aos-duration="1500" className="w-[90px] flex gap-2 items-center text-white mx-auto bg-[#F48C06] py-2 cursor-pointer px-4 rounded-md">
            View <FontAwesomeIcon icon={faArrowRight} />
          </Link>
    </div>
  );
}

export default Knowledge;
