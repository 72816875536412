
import podinimg from "../Assets/Images/Podin.png";
import 'aos/dist/aos.css'; // Import AOS CSS
import React, { useEffect } from 'react';

import Tangibility from "../Assets/Images/Tangibility.png";
function Secthree() {

  

  return (
    <>
    <div className="grid md:grid-cols-2 max-w-[1920px] mx-auto">
      <div data-aos="fade-right" data-aos-duration="1500">
        <img src={podinimg} className="w-[100%] h-full"/>
      </div>

      <div className="bg-[#ff8600] flex items-center p-[7%] text-[white]" data-aos="fade-left" data-aos-duration="1500">
        <div>
          
          <h3 className="font-semibold py-4 text-3xl xl:text-4xl">Point Of Desire and Instant Need</h3>
          <p className="leading-[35px] text-justify lg:text-start xl:w-[550px]">
            We are taking the age of ‘data and information at your fingertips’
            and the technologies driving it into the future and beyond, and
            realizing ‘services at your fingertips’. “Get it done” and
            “Productivity with every connection” means meeting every consumer
            and user at their ‘podin’.
          </p>
        </div>
      </div>
    </div>




<div className="grid md:grid-cols-2 max-w-[1920px] mx-auto">

<div className="md:hidden" data-aos="fade-up" data-aos-duration="1500">
  <img src={Tangibility} className="w-[100%] h-full"/>
</div>



<div className="bg-[#ff8600] flex items-center p-[7%] text-[white]" data-aos="fade-down" data-aos-duration="1500">
  <div>

    <h3 className="font-semibold py-4  text-3xl xl:text-4xl">Product and Community</h3>
    <p className="leading-[35px] text-justify lg:text-start xl:w-[580px]">
    At the end, it all comes down to tangibility. <br></br>
All creatures need to be hardwired to earth or physically connected to the universe to survive – eat, drink, sleep, share and love.<br/><br/>

Despite the world becoming increasingly more and more connected yet increasingly more and more virtualized, tangibility remains an indispensable part of commerce and communication. Industry comprises of common categories for providers of similar products (goods and services) while Markets comprise of segments of consumers with similar interests, outlook and needs (community – sectorial, global or commercial). 
<br/>The Goollooper platform is offering and preserving channels to tangibility – of markets and industries – in their most productive forms.
    </p>
  </div>
</div>

<div className="hidden md:block" data-aos="fade-right" data-aos-duration="1500">
  <img src={Tangibility} className="w-[100%] h-full"/>
</div>


</div>
</>
  );
}

export default Secthree;
