import React, { useState, useEffect } from 'react';
import { Circles } from 'react-loader-spinner';

function Loader() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);

    // Clear the timer when the component unmounts or when the visibility changes to false
    return () => clearTimeout(timer);
  }, []); // This effect runs only once after the component mounts

  return (
    <div className={`bg-[#F48C06] loader1 min-h-[100vh] flex justify-center items-center fixed w-[100%] z-[9999999999999999999] ${visible ? '' : 'hidden'}`}>
      <Circles
        height="80"
        width="80"
        color={"white"}
        secondaryColor={"rgba(0, 157, 255, 1)"}
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

export default Loader;
