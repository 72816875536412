import React from 'react';
import logo from '../Assets/Images/Logo.png';
import 'aos/dist/aos.css'; // Import AOS CSS


function Mypf() {
 

  // Define an array containing the data for each section
  const sections = [
    {
      title: 'Owner / Customer',
      description: 'inputs request, creates go-list, and blasts.',
    },
    {
      title: 'Doer / Service Provider',
      description: '– first to accept gets first audience with Owner. All other interested Doers/Providers are placed on standby.',
    },
    {
      title: 'Owner / Customer',
      description: 'and if commercial, Owner is invoiced, and Doers/Providers is paid and reviewed by Owner.',
    },
    // Add more objects with different data for each section as needed
  ];

  return (
    <div className='py-20 px-[7%]'>
      <h2 className='text-center font-semibold text-2xl md:text-4xl pb-6' data-aos="fade-down" data-aos-duration="1500">Manage your projects fast and with ease</h2>
      <p className='text-center text-[black] opacity-[70%] text-lg' data-aos="fade-up" data-aos-duration="1500">As easy as 1, 2, 3:</p>

      <div className='md:flex items-center justify-center gap-10 mt-10'>
        <div className='mt-12'>
          <img src={logo} alt="Logo" className='mx-auto lg:w-[350px]' data-aos="flip-up" data-aos-duration="1500"/>
        </div>

        <div>
          {/* Map over the sections array to render each section */}
          {sections.map((section, index) => (
            <div key={index} className=' md:flex mt-12 gap-4'>
              <div data-aos="fade-right" data-aos-duration="1500" className='bg-[#fff3e6] h-10 w-10 rounded-full flex items-center justify-center text-[#F48C06]'>
                <h3 >{index + 1}</h3>
              </div>

              <div data-aos="fade-left" data-aos-duration="1500">
                <h3 className='font-semibold pb-2 text-xl text-[#F48C06] mt-2 md:mt-0'>{section.title}</h3>
                <p className='text-[black] opacity-[70%] lg:w-[370px] md:w-[320px] text-justify md:text-start'>{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Mypf;
