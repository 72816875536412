import React, { useEffect, useLayoutEffect } from "react";
import logo from "../Assets/Images/White Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function BlogFooter() {
  // Scroll to top on component mount or update
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="lg:py-[5%] py-[10%] flex justify-center bg-[#ff8600]">
      <div>
        <Link to="/" onClick={scrollToTop}>
          <img src={logo} className="w-[100px] mx-auto object-cover" />
        </Link>

        <div className="link flex gap-10 justify-center md:gap-16 my-10">
          <Link
            to="/"
            onClick={scrollToTop}
            className="text-[white] text-lg font-normal hover:border-b border-white ease-in-out duration-100"
          >
            Home
          </Link>
          <Link
            to="/Blog"
            onClick={scrollToTop}
            className="text-[white] text-lg font-normal border-b border-white"
          >
            Blogs
          </Link>
        </div>

        <div className="flex gap-8 justify-center text-2xl text-[white]">
          <a
            href="https://www.facebook.com/profile.php/?id=100091622887805"
            target="blank"
            className=""
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://twitter.com/Goollooper35517"
            target="blank"
            className=""
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://www.linkedin.com/company/goollooper-inc/about/"
            target="blank"
            className=""
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>

        <p className="text-[white] text-lg pt-6 text-center">
          © Copyright 2024 - Goollooper
        </p>
      </div>
    </div>
  );
}

export default BlogFooter;
