import React from 'react';
import icon from "../Assets/Images/Feature icon.png";
import 'aos/dist/aos.css'; // Import AOS CSS

function Features() {

  return (
    <div className="my-28 max-w-[1920px] mx-auto">
      <p data-aos="fade-up" data-aos-duration="1500" className="text-center text-[#F48C06] text-md font-semibold bg-[#F9F5FF] w-[150px] mx-auto py-2 rounded-2xl">
        Features
      </p>
      <h2 className="text-center text-2xl md:text-4xl font-semibold py-4" data-aos="fade-left" data-aos-duration="1500">
        What is Goollooper?
      </h2>
      <p className="text-[#F48C06] text-center sm:text-xl" data-aos="fade-right" data-aos-duration="1500">
        Goollooper is a social productivity app that:
      </p>

      <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-10 xl:px-[1%] xl:w-[1270px] mx-auto px-[5%] justify-between mt-20">
        <div data-aos="fade-right" data-aos-duration="1500">
          <img src={icon} className="mx-auto" />
          <h3 className="font-semibold text-xl text-center py-4">
            Enables you to schedule a function, event, or job:
          </h3>
          <p className="text-[#F48C06] text-center font-normal">
            1. With as many people as you want
          </p>
          <p className="text-[#F48C06] text-center font-normal">
            2. With the autonomy to select whoever you want
          </p>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500">
          <img src={icon} className="mx-auto" />
          <h3 className="font-semibold text-xl text-center py-4">
            Instant full scheduling and complete hiring in ONE CLICK
          </h3>
        </div>

        <div data-aos="fade-right" data-aos-duration="1500">
          <img src={icon} className="mx-auto" />
          <h3 className="font-semibold text-xl text-center py-4">
            Offers lead cultivation and harvesting opportunities:
          </h3>
          <p className="text-[#F48C06] text-center font-normal">
            1. With practically zero (exponentially diminishing) cost per lead
            (CPL) over time
          </p>
          <p className="text-[#F48C06] text-center font-normal">
            2. With the highest possible conversion potential
          </p>
        </div>
      </div>
    </div>
  );
}

export default Features;
