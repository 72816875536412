import React, { useEffect, useLayoutEffect } from "react";
import logo from "../Assets/Images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  // Scroll to top on component mount or update
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const location = useLocation(); // Get the current location

  return (
    <div className="lg:py-[5%] py-[10%] flex justify-center">
      <div>
        <Link to="/" onClick={scrollToTop}>
          <img src={logo} className="w-[100px] mx-auto" />
        </Link>

        <div className="link flex gap-10 justify-center md:gap-16 my-10">
          <Link
            to="/"
            onClick={scrollToTop}
            className={`text-[#ff8600] hover:border-b hover:border-[#ff8600] text-lg font-normal ${
              location.pathname === "/" ||
              (location.pathname === "/Blog" &&
                !location.pathname.startsWith("/Blog"))
                ? "border-b border-[#ff8600]"
                : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/Blog"
            onClick={scrollToTop}
            className={`text-[#ff8600] text-lg font-normal hover:border-b border-[#ff8600] ${
              location.pathname === "/Blog" ? "border-b border-[#ff8600]" : ""
            }`}
          >
            Blogs
          </Link>
        </div>

        <div className="flex gap-8 justify-center text-2xl text-[#ff8600]">
          <a
            href="https://www.facebook.com/profile.php/?id=100091622887805"
            target="blank"
            className=""
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://twitter.com/Goollooper35517"
            target="blank"
            className=""
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://www.linkedin.com/company/goollooper-inc/about/"
            target="blank"
            className=""
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>

        <p className="text-[#ff8600] text-lg pt-6 text-center">
          © Copyright 2024 - Goollooper
        </p>
      </div>
    </div>
  );
}

export default Footer;
