// Blogs.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import defaultImageUrl from "../Assets/Images/Blog-Banner.png";
import { Base_url } from "../Constent";

const Blogs = ({ isHomePage }) => { // Add prop to indicate whether it's the homepage
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(isHomePage ? 3 : 12); // Adjust page size for homepage
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          ` https://magical-attraction-70239154c0.strapiapp.com/api/blogs?populate=*&sort[0]=createdAt:desc&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
        );

        setTotalCount(response.data.total);

        if (response.data && response.data.data) {
          setBlogs(response.data.data);
        } else {
          console.error(
            "Invalid API response format: 'data' property not found"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [page, pageSize]);

  return (
    <>
      <section className="blogpage-main w-full flex justify-center relative top-[0px] py-[100px]">
        <div className={`w-full xl:max-w-[1920px] relative mx-[7%]`}>
          <div className="relative top-[10px] flex flex-col gap-y-[32px]">
            <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 justify-between gap-x-[50px] lg:gap-x-[100px] gap-y-[40px] lg:overflow-hidden">
              {blogs !== undefined ? (
                blogs?.length ? (
                  blogs.slice(0, isHomePage ? 3 : blogs.length).map((blog, ind) => { // Slice blogs array to display only 3 on homepage
                    let today = new Date(blog?.attributes?.createdAt);
                    let options = {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };
                    let formattedDate = today.toLocaleDateString(
                      "en-US",
                      options
                    );

                    // let thumbnailData = blog?.attributes?.thumbnail?.data;
                    // let thumbnailUrl =
                    //   thumbnailData && thumbnailData.length > 0
                    //     ? thumbnailData[0]?.attributes?.url
                    //     : defaultImageUrl;

                        let thumbnailData = blog?.attributes?.thumbnail?.data;
                    let thumbnailUrl =
                      thumbnailData && thumbnailData.length > 0
                        ? thumbnailData[0]?.attributes?.url
                        : defaultImageUrl;
                    console.log(thumbnailUrl);


                    return (
                      <Link
                        to={`/blogs/${blog?.attributes?.slug}`}
                        className="w-full pb-[0px] flex flex-col"
                        key={ind} data-aos="flip-left" data-aos-duration="1500"
                      >
                        <div className="img-container h-[290px]">
                          <img
                            src={Base_url + thumbnailUrl}
                            alt="img"
                            className="w-[100%] rounded-xl h-[100%] object-cover"
                          />
                        </div>
                        <div className="py-[20px] text-white">
                          <span className="text-[16px] font-oswald font-bold">
                            {blog?.attributes?.title}
                          </span>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div className="loader-container2">
                    {totalCount > 0 ? (
                      <p style={{ color: "white", fontSize: "18px" }}>
                        Data not found
                      </p>
                    ) : (
                      <Circles
                        height="80"
                        width="80"
                        color={"white"}
                        secondaryColor={"rgba(0, 157, 255, 1)"}
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    )}
                  </div>
                )
              ) : (
                <p className="w-full text-center text-[yellow] my-[3rem]">
                  !No data Founds
                </p>
              )}
            </div>
            {totalCount > blogs?.length ? (
              <button className="w-fit px-[40px] text-green-400 mx-auto uppercase font-['Poppins']">
                Read More
              </button>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
