import React from "react";

function Content() {
  return (
    <div className="py-[7%] px-[7%] bg-[black] text-white content-bg flex items-center mx-auto max-w-[1920px]">
      <div className="">
      <h2 className="text-2xl lg:text-3xl lg:text-center font-semibold pb-10 lg:pb-16" data-aos="flip-up" data-aos-duration="1500">
        What is a social productivity app and what can it do?
      </h2>
      <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500">
        We know now - through the enormous success of social media apps - that
        people like nostalgia, showing off, staying connected, variety, and
        sharing in other people's experiences and having others share in theirs.
        Data reveal that social apps are responsible for 50% of app usage time.
        </p>

        <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500">
        People also yearn for as much autonomy as possible in their expertise
        and over their possessions; take pride in 'doing', especially what they
        love or are good at; want to be recognized for something they do even
        marginally well; love opportunities - to receive, to create, to share
        in, and to exploit as many as possible; and ultimately, are in need of a
        medium that places accessing these opportunities literally at their
        fingertips - as a consumer or as a provider.</p>
        
        
        <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500">
         The term “Social
        Productivity” is not derived from social media apps and productivity
        apps, but a cross between utility app and lifestyle app functionalities.
        That is, social - as in 'society', and productivity - as in business and
        economics measure of performance (output/input; actual/planned;
        accomplishment/effort; produced/spent; sales/ spent; etc.).Why a
        lifestyle app? Lifestyle apps are the category of apps that support an
        individual lifestyle or accelerate or provide ease and/or comfort for a
        set of routines that make up the individual's lifestyle. Therefore,
        people turn to these apps with rigid frequency - daily, hourly,
        minute-to-minute. These are generally classified under fitness, dating,
        food, music and travel. Goollooper enters this space under “interest &
        responsibilities” classification. By providence or design, interests and
        responsibilities inadvertently constitute lifestyle for many. And
        because interests and responsibilities are fixed or recurring trends in
        people's lives, people turn to them from time to time to find solutions
        to problems and to upgrade or invite changes in their lifestyle.</p>
        
        <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500">Why a
        utility app? Utility apps are the category of apps that serve specific
        and practical functionalities, like reminders, trackers, calculations,
        navigation, filing, etc. Goollooper enters this space as a "finder" -
        for locating and designating tasks and taskers; and as a “tasklator" -
        tool to create/receive, and to schedule and coordinate the assignment of
        tasks. </p>
        
        <h2 className="text-2xl lg:text-3xl font-semibold pb-8" data-aos="flip-up" data-aos-duration="1500">
        What makes goollooper stand out and why is it the better option
        and <br className="hidden lg:block"></br>alternative?</h2>
        
        
        <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500"> The answer is goollooper's features, functionality and
        operational model. Let's consider the operational structure and
        corresponding features and functionalities of nearly all production
        based apps - predominantly productivity apps (for increasing the
        efficiency of scheduling and accomplishing tasks) and employment apps
        (expertise recruitment, job staffing and hiring). Their M.O.s generally
        fall under 2 categories. In the first category are the recruiter app.
        With these apps, there's first a job posting, then there's applications,
        then there's an attempt to facilitate matches and connections through a
        system of business or organizational and technological services. It's a
        one-dimensional top-down system of commerce. </p>
        
        <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500">In the second category, are
        the freelancer and gig apps. With these, there's a search and request
        for a service on one end, and an enrollment and interest in providing
        said services on the other end; then like with the first category,
        there's then the action of business/organizational and technological
        systems that match and connect both ends. It's a low-connectivity
        networking system of commerce.</p>
        
        <p className="pb-8 lg:text-xl text-justify" data-aos="flip-up" data-aos-duration="1500"> With both categories, productivity is
        completely subject to the efficacy of the `middleman' - the
        business/organizational and technological systems that determine the
        rate and flow of productivity. The rate and flow of production is
        confined to a set of interdependent stages of operation (posting,
        application, search enrollment) with each stage producing a loss of
        efficiency, so that by the time a match is made, the entire system would
        have expended a disproportionate amount of time and resources.
        Goollooper on the other hand, is all agile, all connection and all
        productivity. There are no chasms, and it's all dynamic, all
        interactive, all people driven - not system driven.
      </p>
      </div>
    </div>
  );
}

export default Content;
