import React from 'react'
import Header from '../Containers/Header'
import Insight from '../Containers/Insight'
import Secthree from '../Containers/Secthree'
import Mypf from '../Containers/Mypf'
import Homeblog from '../Blogs/Homeblog'
import Content from '../Containers/Content'
import Footer from '../Components/Footer'
import Knowledge from '../Containers/Knowledge'
import Chatbot from '../Chatbot/Chatbot'
import Contact from '../Containers/Contact'
import Loader from '../Components/Loader'
import { Helmet } from 'react-helmet';


function Home() {
  return (
    <div>

      <Loader/>
          <Chatbot />
      <Header/>
      <Insight/>
       <Secthree/>
      <Mypf/>
      <Homeblog/>
     <Content/>
     <Knowledge/>
     <Contact/>
   <Footer/>
     </div>
  )
}

export default Home
