import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import KnowledgeBase from "./Pages/KnowledgeBase";
import Blogdetails from "./Blogs/BlogDetails";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

function App() {
  useEffect(() => {

    // Initialize AOS
    AOS.init({
      disable: false,
      startEvent: 'DOMContentLoaded',
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate',
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,
      offset: 120,
      delay: 0,
      duration: 400,
      easing: 'ease',
      once: false,
      mirror: false,
      anchorPlacement: 'top-bottom',
    });
  }, []); // Empty dependency array ensures this effect runs only once after mount

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/KnowledgeBase" element={<KnowledgeBase />} />
        <Route path="/blogs/:slug" element={<Blogdetails />} />
      </Routes>
    </Router>
  );
}

export default App;
