import React, { useState } from "react";
import { Link } from "react-router-dom";

function Contact() {
  const [countryCode, setCountryCode] = useState("+1"); // Default country code for US

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  // List of countries and their country codes
  const countries = [
    { name: "US", code: "+1", abbreviation: "United States" },
    { name: "UK", code: "+44", abbreviation: "United Kingdom" },
    { name: "AU", code: "+61", abbreviation: "Australia" },
    { name: "DE", code: "+49", abbreviation: "Germany" },
    { name: "FR", code: "+33", abbreviation: "France" },
    { name: "IT", code: "+39", abbreviation: "Italy" },
    { name: "JP", code: "+81", abbreviation: "Japan" },
    { name: "KR", code: "+82", abbreviation: "South Korea" },
    { name: "CN", code: "+86", abbreviation: "China" },
    { name: "IN", code: "+91", abbreviation: "India" },
    { name: "BR", code: "+55", abbreviation: "Brazil " },
    { name: "MX", code: "+52", abbreviation: "Mexico" },
    { name: "RU", code: "+7", abbreviation: "Russia" },
    { name: "ES", code: "+34", abbreviation: "Spain" },
    { name: "NL", code: "+31", abbreviation: "Netherlands" },
    { name: "SE", code: "+46", abbreviation: "Sweden" },
    { name: "CH", code: "+41", abbreviation: "Switzerland" },
    { name: "No", code: "+47", abbreviation: "Norway" },
    { name: "DK", code: "+45", abbreviation: "Denmark" },
    { name: "FI", code: "+358", abbreviation: "Finland" },
    { name: "SG", code: "+65", abbreviation: "Singapore" },
    { name: "NZ", code: "+64", abbreviation: "New Zealand" },
    { name: "ZA", code: "+27", abbreviation: "South Africa" },
    { name: "TR", code: "+90", abbreviation: "Turkey" },
    { name: "AR", code: "+54", abbreviation: "Argentina" },
    { name: "CO", code: "+57", abbreviation: "Colombia" },
    { name: "CL", code: "+56", abbreviation: "Chile" },
    { name: "SA", code: "+966", abbreviation: "Saudia Arabia" },
    { name: "AE", code: "+971", abbreviation: "United Arab Emirates" },
    { name: "PK", code: "+92", abbreviation: "Pakistan" },
    { name: "BD", code: "+880", abbreviation: "Bangladesh" },
    { name: "ID", code: "+62", abbreviation: "Indonesia" },
    { name: "PH", code: "+63", abbreviation: "Philippines" },
    { name: "VN", code: "+84", abbreviation: "Vietnam" },
    { name: "TH", code: "+66", abbreviation: "Thailand" },
    { name: "MY", code: "+60", abbreviation: "Malaysia" },
    { name: "IR", code: "+98", abbreviation: "Iran" },
    { name: "IQ", code: "+964", abbreviation: "Iraq" },
    { name: "AF", code: "+93", abbreviation: "Afghanistan" },
  ];

  return (
    <div className="Contact-main py-20 px-[7%] flex justify-center items-center max-w-[1920px] mx-auto">
      <div>
        <h2 className="text-lg text-[#F48C06] text-center"  data-aos="fade-left" data-aos-duration="1500">Contact us</h2>
        <h3 className="text-center text-4xl text-white font-semibold py-4"  data-aos="fade-right" data-aos-duration="1500">
        We'd love to hear from you!
        </h3>
        <p className="text-center text-white text-lg"  data-aos="fade-up" data-aos-duration="1500">
          Please fill out this form.
        </p>

        <form className="pt-12 w-[100%]">
          <div className="sm:flex gap-4">
            <div className="mt-8">
              <label className="text-white"  data-aos="fade-left" data-aos-duration="1500">First name</label>
              <br></br>
              <input  data-aos="fade-up" data-aos-duration="1500"
                type="text"
                placeholder="First name"
                className="mt-2 w-[100%]"
                required
              ></input>
            </div>
            <div className="mt-8">
              <label className="text-white"  data-aos="fade-left" data-aos-duration="1500">Last name</label>
              <br></br>
              <input  data-aos="fade-up" data-aos-duration="1500"
                type="text"
                placeholder="Last name"
                className="mt-2 w-[100%]"
                required
              ></input>
            </div>
          </div>

          <div className="mt-8">
            <label className="text-white"  data-aos="fade-up" data-aos-duration="1500">Email</label>
            <br></br>
            <input  data-aos="fade-left" data-aos-duration="1500"
              type="email"
              placeholder="you@company.com"
              className="mt-2 w-[100%]"
              required
            ></input>
          </div>

          <div className="mt-8">
            <label className="text-white"  data-aos="fade-up" data-aos-duration="1500">Phone number</label>
            <br></br>
            <div className="flex"  data-aos="fade-left" data-aos-duration="1500">
              <select
                className="mt-2 outline-none text-gray-400 pl-1 text-md country2 w-[47px]"
                value={countryCode}
                onChange={handleCountryCodeChange}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.code} className="">
                    {country.name} ({country.abbreviation})
                  </option>
                ))}
              </select>
              <input
                type="tel"
                placeholder={` ${countryCode} (555) 000-0000`}
                className="mt-2 w-[100%] country"
                required
              ></input>
            </div>
          </div>

          <div className="mt-8">
            <label className="text-white" data-aos="fade-up" data-aos-duration="1500">Message</label>
            <br></br>
            <textarea data-aos="fade-left" data-aos-duration="1500"
              type="text"
              className="mt-2 w-[100%] h-[200px]"
              required
            ></textarea>
          </div>

          <div>
            <button className="text-white bg-[#F48C06] w-[100%] mt-8 py-[10px] rounded-[5px]" data-aos="fade-down" data-aos-duration="1500">
              Send message
            </button>
          </div>

          <div className="mt-8 text-center text-[#F48C06] font-semibold border-b border-b-[#F48C06] w-[152px] mx-auto">
            <Link to="/" data-aos="fade-up" data-aos-duration="1500">Feedback & Review</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
