// BlogDetails.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as farThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { Circles } from "react-loader-spinner";
import defaultCoverImageUrl from "../Assets/Images/Blog-Banner.png";
import BlogFooter from "../Components/BlogFooter";
import BlogDetailsNav from '../Components/BlogDetailsNav';
import Chatbot from "../Chatbot/Chatbot";
import Loader from "../Components/Loader";
import { Base_url } from "../Constent";

const BlogDetails = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          ` https://magical-attraction-70239154c0.strapiapp.com/api/blogs?filters[slug][$eq]=${slug}&populate=*`
        );
        setBlog(response.data?.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const handleLikeClick = async () => {
    // Your like handling logic
  };

  return (
    <>

      

    <Loader/>
     <Chatbot />
    <BlogDetailsNav/>
    <section className="flex justify-center pt-[100px] px-[7%]  relative top-[0px]">
      <div className="pt-[50px] pb-[50px] w-full xl:max-w-[1290px]">
        {blog ? (
          <>
            <div className=" font-jakarta">
              <Link to="/blog">
                <span className="text-[rgb(140,140,140)] text-[17px] cursor-pointer">
                  Blog &nbsp;/&nbsp;&nbsp;
                </span>
              </Link>
              <span className="text-[#1D356A] text-[17px] font-bold">
                {blog.attributes.title}
              </span>
            </div>
            <div className="pt-[20px]  flex flex-col gap-y-[21px] justify-center md:items-center">
              <h1 className="text-[32px] font-poppins font-bold text-[#161B36] lg:text-[48px]  md:text-center">
                {blog.attributes.title}
              </h1>
              <span className="font-roboto text-[17px] text-[#161B36]">
                {blog.attributes.count} Reads
              </span>
            </div>
            <div className="img-container py-[48px]">
              {blog.attributes &&
              blog.attributes.cover &&
              blog.attributes.cover.data &&
              blog.attributes.cover.data.length > 0 ? (
                <img
                src={`${Base_url}${blog.attributes.cover.data[0].attributes.url}`}
                  alt="img"
                  className="w-[100%] "
                />
              ) : (
                <img src={defaultCoverImageUrl} alt="default img" className=" w-[100%]"/>
              )}
            </div>
            <div className=" flex flex-col gap-y-[32px] font-jakarta">
              <div className="flex gap-x-[9px] justify-center items-center">
                <button className="text-[#161B36] text-xl" onClick={handleLikeClick}>
                  <FontAwesomeIcon
                    icon={isLiked ? faThumbsUp : farThumbsUp}
                  />
                </button>
                <span className="text-[14px] text-[#161B36] font-roboto">
                  {blog.attributes.likes}
                </span>
              </div>
              <p
                className="text-[#161B36] font-roboto"
                dangerouslySetInnerHTML={{
                  __html: blog.attributes.description,
                }}
              ></p>
            </div>
          </>
        ) : (
          <div className="loader-container flex justify-center">
            <Circles
              height="80"
              width="80"
              color={"#ff8600"}
              secondaryColor={"rgba(0, 157, 255, 1)"}
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
    </section>
    <BlogFooter/>
    </>
     );
    };

 

export default BlogDetails;
