import React from 'react';
import BlogCards from './BlogCards';

function Homeblog() {

  return (
    <div className='py-[10%] md:py-[4%] max-w-[1920px] mx-auto bg-[#ff8600] home-blog-bg'>
      <h2 className='text-white text-center text-3xl md:text-5xl font-semibold'  data-aos="fade-down" data-aos-duration="1500">Read Our Blog</h2>
<BlogCards isHomePage={true}/>
    </div>
  );
}

export default Homeblog;
