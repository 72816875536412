import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios library
import logo from "../Assets/Images/Chat-bot.png";
import logo1 from "../Assets/Images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chat from '../Assets/Images/Chat.png';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Chatbot({ location }) {
  const [showPhase1, setShowPhase1] = useState(false);
  const [showPhase2, setShowPhase2] = useState(false);
  const [typing, setTyping] = useState(false); // State to track typing
  const [messages, setMessages] = useState([]); // State to store user messages

  useEffect(() => {
    // Hide phases and clear messages when navigating to a new page
    setShowPhase1(false);
    setShowPhase2(false);
    setMessages([]); // Clear messages
  }, [location]);

  const togglePhases = () => {
    if (showPhase2) {
      // Close both phases when phase 2 is active
      setShowPhase1(false);
      setShowPhase2(false);
      setMessages([]); // Clear messages
    } else {
      setShowPhase1(!showPhase1);
      setShowPhase2(false); // Hide phase 2 when toggling phase 1
    }
  };

  const startChat = () => {
    setShowPhase1(false); // Hide phase 1 when starting chat
    setShowPhase2(true); // Show phase 2 when starting chat
  };

  const handleInputChange = (event) => {
    if (event.target.value.trim() !== "") {
      setTyping(true); // Set typing state to true if input is not empty
    } else {
      setTyping(false); // Set typing state to false if input is empty
    }
  };

  const sendMessage = async () => {
    const input = document.getElementById("chatInput");
    const message = input.value.trim();
    if (message !== "") {
      setMessages([...messages, message]);
      input.value = ""; // Clear input after sending message
      setTyping(false); // Reset typing state
      
      try {
        const response = await axios.post("https://api.openai.com/v1/chat/completions", { message }); // Replace "YOUR_API_ENDPOINT" with your actual API endpoint
        const botResponse = response.data; // Assuming the response contains the bot's message
        setMessages([...messages, botResponse]); // Add bot's response to messages
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <>
      <div className=" fixed bottom-20 bg-green-400 z-[999999] right-[100px] lg:right-28">
        {/* Chatbot container */}
        <div className={showPhase2 ? "Chattbott-Phase2  right-[20px] lg:right-10 bg-white w-[250px] sm:w-[300px]" : "hidden"}>
          {/* Phase 2 content */}
          <div className="flex sec-bott gap-4 text-lg sm:px-[20px] sm:py-[20px] rounded-tl-lg rounded-tr-lg px-2 py-4  mb-2 sm:mb-8 font-bold items-center">
            <img src={chat} className="w-[40px] md:w-[auto]"/>
            <div>
              <h1 className="text-lg">Customer Support</h1>
              <p className="bg-[#F48C06] py-1 px-2 text-sm w-[137px] text-[white] mt-2">AABBCHHFG94T</p>
            </div>
          </div>
          <div className="lg:h-[220px] sm:h-[150px] h-[200px] px-4 overflow-hidden overflow-y-auto">
            {messages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </div>
          <div className="sm:px-[20px] sm:py-[20px] p-2 chatbott-message border-bl-lg border-br-lg flex justify-between items-center">
            <input id="chatInput" type="text" placeholder="Ask a question ..." className="outline-none chattbott-input " onChange={handleInputChange}></input>
            {typing && <FontAwesomeIcon icon={faArrowRight} onClick={sendMessage} className="cursor-pointer text-lg pr-4 text-[#F48C06]" />}
          </div>
        </div>

        {showPhase1 && (
          <div className="sm:p-[30px] right-[20px] lg:right-10 p-4 bg-white w-[200px] sm:w-[300px]  chatbot-phase1">
            {/* Phase 1 content */}
            <img src={logo1} className="w-[70px] sm:w-[150px] mx-auto flex" />
            <h1 className="sm:text-2xl font-semibold text-center py-4">
              Customer Support
            </h1>
            <div className="flex justify-center mt-8">
              <button className="text-white bg-[#F48C06] py-2 cursor-pointer px-4 rounded-md" onClick={startChat}>
                Start Chat <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}

        <div
          className="chatbot-container"
          onClick={togglePhases}
        >
          <img src={logo} alt="Chatbot" className="cursor-pointer" />
        </div>
      </div>
    </>
  );
}

export default Chatbot;
