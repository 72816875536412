import React from "react";
import BlogCards from "../Blogs/BlogCards";
import Footer from "../Components/Footer";
import BlogNav from "../Components/BlogNav";
import Chatbot from "../Chatbot/Chatbot";
import Loader from "../Components/Loader";

function Blog() {
  return (
    <>

      <Loader />
      <Chatbot />
      <div className="Blogpage-bg pt-[150px] pb-10">
        <BlogNav />
        <div>
          <h1
            className="text-white text-center text-3xl md:text-5xl font-semibold"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            Read Our Blog
          </h1>
          <BlogCards />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
