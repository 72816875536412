import React, { useState } from "react";
import { Link } from "react-router-dom";
import cardimg1 from "../Assets/Images/Faq.png";
import cardimg2 from "../Assets/Images/TroubleShooting.png";
import cardimg3 from "../Assets/Images/BestPractise.png";
import cardimg4 from "../Assets/Images/Usingtheapp.png";
import cardimg5 from "../Assets/Images/OrganizationUpdate.png";
import HomeNav from "../Components/HomeNav";
import BlogFooter from "../Components/BlogFooter";
import FAQ from "../KnowledgeBase-Containers/FAQ";
import Trouble from "../KnowledgeBase-Containers/Trouble";
import BestPractises from "../KnowledgeBase-Containers/BestPractises";
import Appdemo from "../KnowledgeBase-Containers/Appdemo";
import Organizatipon from "../KnowledgeBase-Containers/Organizatipon";
import Chatbot from "../Chatbot/Chatbot";
import Loader from '../Components/Loader';
// Import other images as needed

function KnowledgeBase() {
  
  const [selectedComponent, setSelectedComponent] = useState(<FAQ />);
  const [activeIndex, setActiveIndex] = useState(0);

  // Define an array of card data
  const cardsData = [
    { id: "faq", component: <FAQ />, image: cardimg1, heading: "FAQ" },
    { id: "troubleshooting", component: <Trouble />, image: cardimg2, heading: "Troubleshooting Tips" },
    { id: "bestpractices", component: <BestPractises />, image: cardimg3, heading: "Best Practices" },
    { id: "appdemo", component: <Appdemo />, image: cardimg4, heading: "Using the App Demo" },
    { id: "organizationupdate", component: <Organizatipon />, image: cardimg5, heading: "Organization Update" },
    // Add more objects for additional cards with different data
  ];

  const handleClick = (index, component) => {
    setSelectedComponent(component);
    setActiveIndex(index);
  };

  return (
    <>
    <Loader/>
      <HomeNav />
      <Chatbot />
      <div className="px-[7%]">
      <div className="pt-32 lg:pt-52 pb-20 max-w-[1920px] mx-auto">
        <h1 className="text-3xl xl:text-5xl font-semibold text-center"  data-aos="fade-down" data-aos-duration="1500">
          Knowledge Base
        </h1>

        <div className="grid-cols-2 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 justify-center items-center gap-4 my-10">
          {/* Map over the array to render each card */}
          {cardsData.map((card, index) => (
            <div key={index} id={card.id} onClick={() => handleClick(index, card.component)} className={`cursor-pointer relative mt-10 ${activeIndex !== index && 'Head'}`}>
              <div style={{ position: "relative" }}  data-aos="flip-left" data-aos-duration="1500">
                <img
                  src={card.image}
                  alt={card.heading}
                  style={{ width: "100%", height: "auto", borderRadius: "8px", border: activeIndex === index ? "8px solid #ff8600" : "none" }}
                  className="rounded-lg"
                />
                <h3
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  }}
                  className="text-center text-white text-[12px] lg:text-lg font-semibold"
                >
                  {card.heading}
                </h3>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>

      {/* Display selected component */}
      {selectedComponent}

      <BlogFooter />
    </>
  );
}

export default KnowledgeBase;
