import React from "react";
import FAQbanner from "../Assets/Images/Organization-Banner.png";

function Organizatipon() {
  return (
    <div className="px-[7%]">
      <div>
        <div className="content max-w-[1920px] mx-auto pb-32">
          <img src={FAQbanner} className="w-[100%]" data-aos="flip-left" data-aos-duration="1500"/>
          <h1 className="text-2xl lg:text-4xl font-semibold py-8 lg:text-center" data-aos="fade-down" data-aos-duration="1500">
            Organization Update
          </h1>
          
          <p className="py-4 text-[#ff8600]  text-center lg:py-16 text-lg lg:text-2xl font-bold border-b border-[black]" data-aos="fade-left" data-aos-duration="1500">
        updates upcoming....
        </p>

        </div>
      </div>
    </div>
  );
}

export default Organizatipon;
