import React from 'react';
import 'aos/dist/aos.css'; // Import AOS CSS

function Insight() {


  return (
    <>
    <div data-aos="fade-up" data-aos-duration="1500" className="max-w-[1920px] mx-auto insight-main text-[white] flex justify-center items-center lg:px-[2%] py-[10%] px-[7%]">
        <div data-aos="fade-down" data-aos-duration="1500">
      <h2 className="text-[#FFFFFF] lg:text-center pb-8 font-bold text-2xl sm:text-5xl">Get things done with Goollooper</h2>
      <p className="lg:text-center text-lg font-normal opacity-[65%] lg:w-[890px] text-justify lg:mx-auto">
        Regardless of the time, place, or society, for many, every day is a
        series of “doing” and “getting things done.” Sometimes DIY, but a lot of
        times, collaboration is called for, whether by direct involvement in the
        “doing,” or by bringing in another party, or handing it over entirely.
        As a result, at the other end of the spectrum is the “seeking to do,”.
        Regardless of the time, place or society, our every‐day series of
        “doing,” “getting things done,” and “seeking to do” present inherent
        multifaceted challenges, from finding and receiving optimal service to
        seeking and seizing viable opportunities.
      </p>
      </div>
    </div>

<div className="max-w-[1920px] mx-auto GMS-bg flex items-center lg:text-center px-[7%] lg:justify-center" data-aos-duration="1500" data-aos="fade-right">
  <div data-aos="fade-left" data-aos-duration="1500">
    <h2 className="text-[#FFFFFF] lg:text-center pb-4 font-bold text-2xl lg:text-3xl">Goollooper's Mission Statement</h2>
    <p className="text-[#F48C06] lg:text-lg">Goollooper's mission and vision is centered on PODIN and built around <br className="hidden lg:block"></br>Tangibility.</p>
  </div>
</div>

    </>
  );
}

export default Insight;
