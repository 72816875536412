import React from "react";
import FAQbanner from "../Assets/Images/FAQ-banner.png";

function FAQ() {
  return (
    <div className="px-[7%]">
      <div className="content pb-32 max-w-[1920px]  mx-auto">
        <img src={FAQbanner} className="w-[100%]" data-aos="flip-left" data-aos-duration="1500"/>
        <h1 className="text-2xl lg:text-4xl font-semibold py-8 lg:text-center" data-aos="fade-up" data-aos-duration="1500">FAQ</h1>

        <p className="py-4 lg:py-8 text-lg text-justify lg:text-start" data-aos="fade-down" data-aos-duration="1500">
          Welcome to our app's FAQ section. Below are some common questions and
          answers to help you get started. If you don't find the answer you're
          looking for, feel free to contact our support team.
        </p>

        <div className="py-4 lg:py-8 text-justify lg:text-start">
          <p className="question font-semibold text-lg" data-aos="fade-right" data-aos-duration="1500">
            Question: How do I download the app?
          </p>
          <p className="Answer text-lg" data-aos="fade-left" data-aos-duration="1500">
            Answer: You can download our app from the App Store for iOS devices
            or Google Play Store for Android devices. Simply search for
            Goollooper and follow the installation instructions.
          </p>
        </div>

        <div className="py-4 lg:py-8 text-justify lg:text-start">
          <p className="question font-semibold text-lg" data-aos="fade-right" data-aos-duration="1500">
            Question: Is the app free to use?
          </p>
          <p className="Answer text-lg" data-aos="fade-left" data-aos-duration="1500">
            Answer: Yes, our app is free to download and use. However, some
            features may require a subscription or in-app purchase. You can find
            more information about pricing and subscription plans within the
            app.
          </p>
        </div>

        <div className="py-4 lg:py-8 text-justify lg:text-start">
          <p className="question font-semibold text-lg" data-aos="fade-right" data-aos-duration="1500">
            Question: How do I create an account?
          </p>
          <p className="Answer text-lg" data-aos="fade-left" data-aos-duration="1500">
            Answer: To create an account, open the app and click on the "Sign
            Up" or "Register" button. You will be prompted to enter your email
            address and choose a password. Follow the on-screen instructions to
            complete the registration process.
          </p>
        </div>

        <div className="py-4 lg:py-8 text-justify lg:text-start">
          <p className="question font-semibold text-lg" data-aos="fade-right" data-aos-duration="1500">
            Question: I forgot my password. What should I do?
          </p>
          <p className="Answer text-lg" data-aos="fade-left" data-aos-duration="1500">
            Answer: If you forgot your password, you can reset it by clicking on
            the "Forgot Password" link on the login screen. Enter your email
            address, and we'll send you a link to reset your password.
          </p>
        </div>

        <div className="py-4 lg:py-8 text-justify lg:text-start">
          <p className="question font-semibold text-lg" data-aos="fade-right" data-aos-duration="1500">
            Question: Can I use the app offline?
          </p>
          <p className="Answer text-lg" data-aos="fade-left" data-aos-duration="1500">
            Answer: Some features of the app may require an internet connection,
            but you can use certain functionalities offline. For example, you
            can access downloaded content or use certain tools without an
            internet connection.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
