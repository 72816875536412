import React, { useState, useEffect, useLayoutEffect } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import logo1 from "../Assets/Images/Logo.png";
import logo2 from "../Assets/Images/White Logo.png";

function BlogDetailsNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top on component mount or update
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    if (isHomePage) {
      setIsOpen(false); // Close the mobile menu if already on the home page
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className={` ${isSticky ? "sticky" : ""}`}>
      <div className="px-[7%] py-4">
        <div className="flex justify-between items-center max-w-[1920px] mx-auto">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <button className="md:hidden text-white bg-[#F48C06] py-2 cursor-pointer px-4 rounded-md border border-white">
                Download app
              </button>
              <div className="hidden md:block">
                <div className="flex items-baseline space-x-10">
                  <Link
                    to="/"
                    onClick={scrollToTop}
                    className={`font-bold text-lg ${
                      isSticky
                        ? "text-white hover:border-b border-white"
                        : "text-[#0F172A] hover:border-b border-[#0F172A]"
                    }`}
                  >
                    Home
                  </Link>
                  <Link
                    to="/Blog"
                    onClick={scrollToTop}
                    className={`font-bold text-lg ${
                      isSticky
                        ? "text-white border-b border-white"
                        : "text-[#0F172A] border-b border-[#0F172A]"
                    }`}
                  >
                    Blogs
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden md:block">
            {isSticky ? (
              <img src={logo2} id="img2" className="w-16" />
            ) : (
              <img src={logo1} id="img1" className="w-16" />
            )}
          </div>

          <div className="hidden md:block fixed md:sticky">
            <div className="ml-10 flex items-baseline space-x-4">
              <button className="text-white bg-[#F48C06] py-2 cursor-pointer px-4 rounded-md border border-white">
                Download app
              </button>
            </div>
          </div>
          <div className="flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center border border-white justify-center p-2 rounded-md text-[white] hover:text-white  focus:outline-none bg-[#ff7f00] focus:text-white"
            >
              <FontAwesomeIcon
                icon={isOpen ? faTimes : faBars}
                className="block h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`md:hidden ${isOpen ? "block" : "hidden"}`}
        id="mobile-menu"
      >
        <div className="px-[7%] pt-2 pb-3 space-y-1 sm:px-3 min-h-[100vh] md:h-[auto] bg-[#ff8600]">
          <Link
            to="/"
            onClick={scrollToTop}
            className={` font-bold text-lg text-white block pt-4 ${
              isSticky ? "text-white" : "text-[]"
            }`}
          >
            Home
          </Link>
          <Link
            to="/Blog"
            onClick={scrollToTop}
            className={`border-b border-white w-[55px] font-bold text-lg text-white block pt-4 ${
              isSticky ? "text-white" : "text-[]"
            }`}
          >
            Blogs
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default BlogDetailsNav;
